<template lang="pug">
    v-app
        v-main.mt-60
            v-container
                Home
</template>



<script>
    import Home from '@/pages/Home.vue'

    export default {
        components: {
            Home
        }
    }
</script>